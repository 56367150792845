.shop_wrap {
  display: flex;
  max-width: 1000px;
  margin: 2rem auto;

  .loading_spinner {
    height: 400px;
    margin: 10rem auto;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    margin: 2rem 0rem;
    text-align: center;
    padding: 0;

    li {
      list-style-type: none;
      margin: 1rem 1.6rem;

      a {
        color: white;
      }
    }
  }

  ul:after {
    content: "";
    flex: auto;
  }

  .shop_img {
    width: 220px;
    height: auto;
    // image-rendering: crisp-edges;
    // image-rendering: -moz-crisp-edges; /* Firefox */
    // image-rendering: -o-crisp-edges; /* Opera */
    // image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */

    // -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }

  .shop_name {
    width: 250px;
    font-size: 1.5rem;
    overflow-wrap: break-word;
    line-height: 1.8rem;
    margin-bottom: 1rem;
  }
  .shop_price {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 385px) {
  .shop_wrap {
    .shop_img {
      width: 130px;
    }
    .shop_name {
      width: 160px;
    }
  }
}

@media only screen and (min-width: 700px) {
  .shop_wrap {
    margin: 6rem auto;
  }
}

@media (max-width: 960px) {
  .shop_wrap {
    ul:after {
      display: none;
    }
  }
}
