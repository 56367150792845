.header {
  height: 16rem;
  background-color: #292929;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-top: 1.2rem;
    padding-top: 2rem;

    h1 {
      font-family: "Pinyon Script", cursive;
      font-size: 5rem;
      letter-spacing: 0.4rem;
      font-weight: lighter;
    }

    h3 {
      font-weight: 300 !important;
      letter-spacing: 0.6rem;
      font-size: 1.3rem;
      margin-top: -2.2rem;
      margin-bottom: 1.4rem;
    }
  }

  .header_nav {
    color: white;

    ul {
      list-style-type: none;
      display: flex;
      justify-content: space-around;
      background-color: rgb(185, 92, 27);
      padding: 1rem;
      align-items: center;
      height: 2.5rem;
      opacity: 0.99;

      li {
        font-weight: 400;
        font-size: 1.45rem;
        transition: all 0.5s ease;

        .nav {
          color: white;
          text-decoration: none;
        }
        #cart {
          color: #3cb371;
        }
      }
      .nav:hover {
        color: black;
      }
    }
  }
}
@media screen and (min-width: 480px) {
  .header {
    .logo {
      margin-top: 1.2rem;
      h1 {
        font-size: 5.5rem;
      }
      h3 {
        font-size: 1.5rem;
        margin-top: -2.8rem;
        margin-bottom: 2rem;
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .header {
    .logo {
      margin-top: 0rem;
      padding-top: 0rem;
    }
    .header_nav {
      ul {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 2000px) {
  .header {
    .header_nav {
      ul {
        justify-content: space-evenly;
        padding: 0 25rem;
      }
    }
  }
}
