.burgerMenu {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  background: black;
  box-shadow: 0px 0px 2px rgba(174, 174, 174, 0.3);
  height: 100vh;
  text-align: left;
  padding: 1rem 2rem;
  position: absolute;
  top: 20;
  left: 0;
  transition: transform 0.5s ease-in-out;
  z-index: 200;
  transform: translateX(-100%);

  a {
    font-size: 1.45rem;
    font-weight: 400;
    padding: 2rem 0;
    letter-spacing: 0.5rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s linear;

    &:hover {
      color: rgb(185, 92, 27);
    }
  }
}

.burgerMenu.open {
  transform: translateX(0);
}

@media screen and (min-width: 701px) {
  .burgerMenu {
    display: none;
  }
}
