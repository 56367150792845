.the_szunets {
  height: auto;

  .the_szunets_wrap {
    margin: 4rem auto 4rem;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .szunets_img {
      width: 100%;
      height: auto;
      max-width: 1100px;
    }

    h1 {
      font-weight: 300 !important;
      letter-spacing: 0.3rem;
      font-size: 2.2rem;
      line-height: 4rem;
      margin: 0 auto 3rem;
      text-align: center;
    }
    .the_szunets_info {
      width: 85%;
      margin-top: 2rem;
      margin-bottom: 4rem;
      text-align: justify;
      p {
        font-size: 1.5rem;
        line-height: 3rem;
        letter-spacing: 0.15rem;
        word-spacing: 0.05rem;
      }
    }
  }
}

@media only screen and (min-width: 480px) {
  .the_szunets {
    .the_szunets_wrap {
      .the_szunets_info {
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  .the_szunets {
    .the_szunets_wrap {
      margin: 10rem auto 6rem;

      h1 {
        margin: 0 2rem 6.5rem 2rem;
      }

      .the_szunets_info {
        width: 60rem;
        margin-top: 4rem;
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .the_szunets {
    .the_szunets_wrap {
      .the_szunets_info {
        width: 90rem;
      }
    }
  }
}
@media only screen and (min-height: 900px) {
  .the_szunets {
    height: 70vh;
    .the_szunets_wrap {
      .the_szunets_info {
      }
    }
  }
}

@media only screen and (min-height: 1100px) {
  .the_szunets {
    height: 70vh;
    .the_szunets_wrap {
      margin-top: 15rem;
      .the_szunets_info {
      }
    }
  }
}
@media only screen and (min-height: 1300px) {
  .the_szunets {
    height: 68vh;
    .the_szunets_wrap {
      margin-top: 20rem;
      .the_szunets_info {
      }
    }
  }
}
@media only screen and (min-height: 1400px) {
  .the_szunets {
    height: 70vh;
    .the_szunets_wrap {
      margin-top: 20rem;
      .the_szunets_info {
      }
    }
  }
}
