html {
  font-size: 10px;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  color: white;
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Lato";
  font-size: 1.8rem;
  line-height: 1;
  font-weight: 300;
}

a {
  text-decoration: none;
}

a,
a:hover {
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
