.the_artist {
  height: auto;
  font-family: "Lato", serif;

  .the_artist_wrap {
    margin: 4rem auto 6rem;
    width: 80%;

    h1 {
      // font-family: "Bodoni Moda", serif;
      text-align: center;
      font-weight: 300 !important;
      letter-spacing: 0.8rem;
      font-size: 2.3rem;
      line-height: 4rem;

      word-spacing: 0.05rem;
      margin-bottom: 3rem;
    }

    .about {
      .portrait {
        display: block;
        width: 170px;
        // height: 19%;
        // float: right;
        margin: 0 auto 3rem;
        image-rendering: smooth;
      }
      p {
        text-align: justify;
        font-size: 1.5rem;
        line-height: 3rem;
        letter-spacing: 0.15rem;
        word-spacing: 0.05rem;
        float: none;
        margin-bottom: 10rem;
      }
      h2 {
        // font-family: "Bodoni Moda", serif;
        font-weight: 300;
        text-align: center;
        font-size: 3rem;
        line-height: 4rem;
        letter-spacing: 0.15rem;
        word-spacing: 0.05rem;
        margin-bottom: 10rem;
      }
    }
  }
}

@media only screen and (min-width: 500px) {
  .the_artist {
    .the_artist_wrap {
      h1 {
        margin-bottom: 4rem;
      }
      .about {
        .portrait {
          float: right;
          margin-left: 2rem;
          margin-bottom: 1rem;
        }
        p {
          text-align: justify;
          font-size: 1.5rem;
          line-height: 3rem;
          letter-spacing: 0.15rem;
          word-spacing: 0.05rem;
          float: none;
          margin-bottom: 10rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  .the_artist {
    .the_artist_wrap {
      width: 60rem;
      margin: 6rem auto 6rem;

      .about {
        .portrait {
          margin-left: 3rem;
          margin-bottom: 2rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .the_artist {
    .the_artist_wrap {
      width: 90rem;
      margin: 10rem auto 6rem;

      .about {
        .portrait {
          margin-bottom: 2rem;
          margin-left: 5rem;
        }
      }
    }
  }
}

@media only screen and (min-height: 1200px) {
  .the_artist {
    height: 75vh;
  }
}

@media only screen and (min-height: 1400px) {
  .the_artist {
    height: 77vh;
  }
}
