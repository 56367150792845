.product {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  height: auto;

  .loading_spinner {
    height: 400px;
    margin: 10rem auto;
  }

  .page_wrap {
    display: flex;
    flex-direction: column;
    margin: 5rem auto;
    width: 50%;
    justify-content: center;

    .image_wrap {
      // margin-right: 3rem;
      align-self: center;

      img {
        width: 300px;
        height: auto;
      }
    }

    .product_details {
      display: flex;
      flex-direction: column;
      margin-top: 5%;
      align-items: center;

      .product_details_title {
        font-size: 2rem;
        margin-bottom: 2rem;
        // align-self: center;
      }

      .product_details_quantity {
        font-size: 1.6rem;
        margin-bottom: 5rem;
      }
      .products_details_button {
        display: flex;
        width: 330px;
        height: 40px;
        background-color: #3cb371;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
      }
      .product_details_text {
        width: auto;
        margin-top: 0rem;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        h4 {
          margin-top: 3rem;
          font-size: 1.6rem;
          font-weight: 300;
        }

        hr {
          margin-bottom: 4rem;
        }

        p {
          font-size: 1.45rem;
          line-height: 2rem;
          margin-bottom: 4rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 701px) {
  .product {
    height: auto;
    .page_wrap {
      flex-direction: row;
      width: 90%;
      margin: 8rem auto;

      .image_wrap {
        margin-right: 3rem;
      }

      .product_details {
        align-items: start;
        margin-top: 0%;

        .product_details_title {
          font-size: 2.5rem;
          margin-bottom: 1.5rem;
        }

        .product_details_text {
          width: 350px;
          text-align: left;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .product {
    width: 80%;
    margin: 0 auto;
    height: auto;

    .page_wrap {
      margin: 11rem auto;

      .image_wrap {
        margin-right: 0rem;
        img {
          width: 90%;
          height: auto;
        }
      }
    }
    .product_details {
      margin-top: 5%;
    }
  }
}

@media only screen and (min-height: 1100px) {
  .product {
    .page_wrap {
      margin-bottom: 25rem;
    }
  }
}

@media only screen and (min-height: 1200px) {
  .product {
    margin-bottom: 25.5rem;
  }
}
