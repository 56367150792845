.gallery-wrap {
  display: flex;
  width: 100%;
  min-height: 700px;
  margin: 2rem auto 5rem;
}

@media (max-height: 700px) {
  .gallery-wrap {
    margin-bottom: 6rem;
  }
}

@media (min-height: 1000px) {
  .gallery-wrap {
    margin-top: 2rem;
  }
}
