@media only screen and (min-width: 500px) {
  .page-wrap {
    width: 7px;
    display: flex;

    .buttonsPrev {
      position: absolute;
      top: 480px;
      left: 1.6%;
      font-size: 32px;
      cursor: pointer;
      z-index: 1;
    }

    .buttonsNext {
      position: absolute;
      display: flex;
      top: 480px;
      left: 88%;
      font-size: 32px;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 700px) {
  .page-wrap {
    .buttonsPrev {
      left: 17.6%;
    }
    .buttonsNext {
      left: 76%;
    }
  }
}

@media only screen and (min-width: 950px) {
  .page-wrap {
    .buttonsPrev {
      left: 24.6%;
    }
    .buttonsNext {
      left: 71%;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .page-wrap {
    height: 78.5vh;
    .buttonsPrev {
      left: 29.6%;
    }
    .buttonsNext {
      left: 67.5%;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .page-wrap {
    .buttonsPrev {
      left: 30.6%;
    }
    .buttonsNext {
      left: 66.5%;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .page-wrap {
    .buttonsPrev {
      left: 32.6%;
    }
    .buttonsNext {
      left: 66%;
    }
  }
}

@media only screen and (min-width: 1700px) {
  .page-wrap {
    .buttonsPrev {
      left: 33.6%;
    }
    .buttonsNext {
      left: 65%;
    }
  }
}

@media only screen and (min-height: 1100px) {
  .page-wrap {
    margin-top: 8rem;
    .buttonsPrev {
      margin-top: 8rem;
    }
    .buttonsNext {
      margin-top: 8rem;
    }
  }
}

@media only screen and (min-width: 2100px) {
  .page-wrap {
    .buttonsPrev {
      left: 34.9%;
    }
    .buttonsNext {
      left: 65%;
    }
  }
}

@media only screen and (min-width: 2500px) {
  .page-wrap {
    .buttonsPrev {
      left: 35.4%;
    }
    .buttonsNext {
      left: 65%;
    }
  }
}
