.the_series {
  height: auto;

  .the_series_wrap {
    margin: 4rem auto 6rem;
    width: 80%;

    h1 {
      text-align: center;
      font-weight: 300 !important;
      letter-spacing: 0.8rem;
      font-size: 2.3rem;
      line-height: 4rem;
      word-spacing: 0.05rem;
      text-align: center;
      margin-bottom: 3rem;
    }
    p {
      text-align: justify;
      font-size: 1.5rem;
      line-height: 3.5rem;
      letter-spacing: 0.15rem;
      word-spacing: 0.05rem;
      float: none;
      margin-bottom: 4rem;
    }
  }
}

@media only screen and (min-width: 700px) {
  .the_series {
    .the_series_wrap {
      width: 60rem;
      margin: 10rem auto 6rem;
      h1 {
        margin-bottom: 4rem;
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .the_series {
    .the_series_wrap {
      width: 90rem;
    }
  }
}

@media only screen and (min-height: 1000px) {
  .the_series {
    height: 71vh;
  }
}

@media only screen and (min-height: 1200px) {
  .the_series {
    height: 75vh;
  }
}

@media only screen and (min-height: 1400px) {
  .the_series {
    height: 77vh;
  }
}
