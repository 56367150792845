.homepage {
  height: auto;

  .homepage_wrap {
    text-align: center;
    margin: 4rem auto 7rem;
    width: 70%;
    p {
      font-size: 2rem;
      // font-family: "Bodoni Moda", serif;
      line-height: 5rem;
      letter-spacing: 0.2rem;
      word-spacing: 0.1rem;
      margin-bottom: 6rem;
    }

    .homepage_button {
      display: none;
    }

    .the_series_button {
      width: 90%;
      margin: auto;

      .the_series_button_button {
        background-color: black;
        color: white;
        margin: 2rem;
        padding: 1rem 3rem;
        border: 1px solid white;
        font-size: 1.45rem;
        // font-family: "Bodoni Moda", serif;
        line-height: 4rem;
        letter-spacing: 0.2rem;
        word-spacing: 0.1rem;
      }

      .the_series_button_button:hover {
        background-color: #605e5e;
        border: 1px solid #605e5e;
        transition: all 0.5s ease;
      }
    }
  }
}
@media only screen and (min-width: 390px) {
  .homepage {
    .homepage_wrap {
      .the_series_button {
        width: 70%;
      }
    }
  }
}

@media only screen and (min-width: 480px) {
  .homepage {
    .homepage_wrap {
      width: 45rem;
    }
  }
}

@media only screen and (min-width: 700px) {
  .homepage {
    height: 60vh;
    .homepage_wrap {
      width: 60rem;
      margin: 10rem auto 8rem;

      .homepage_button {
        display: inline;
        width: 60%;
        margin: auto;

        .homepage_button_button {
          background-color: black;
          color: white;
          margin: 6rem;
          padding: 1rem 3rem;
          border: 1px solid white;
          font-size: 1.45rem;
          // font-family: "Bodoni Moda", serif;
          line-height: 4rem;
          letter-spacing: 0.2rem;
          word-spacing: 0.1rem;
        }

        .homepage_button_button:hover {
          background-color: #605e5e;
          border: 1px solid #605e5e;
          transition: all 0.5s ease;
        }
      }
      .the_series_button {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .homepage {
    .homepage_wrap {
      width: 80rem;

      .homepage_button {
        width: 40%;

        .homepage_button_button {
          margin: 8rem;
        }
      }
    }
  }
}

@media only screen and (min-height: 900px) {
  .homepage {
    height: 62vh;
    .homepage_wrap {
      margin-top: 16rem;
      p {
        margin-bottom: 9rem;
      }
      .homepage_button {
        width: 60%;
        .homepage_button_button {
          font-size: 1.75rem;
        }
      }
    }
  }
}
@media only screen and (min-height: 1075px) {
  .homepage {
    height: 67.5vh;
  }
}
